import axios from '../libs/axios';
// 积分规则
const levelList = (data) => {
  return axios.get('/api/trd/level/list', data);
};

// 新增/修改
const levelAddOrEdit = (data) => {
  return axios.post('/api/trd/level/save', data);
};

// 信息
const levelInfo = (id) => {
  return axios.post(`/api/trd/level/info/${id}`);
};

// 删除
const levelDelete = (data) => {
  return axios.post('/api/trd/level/delete', data);
};

// 普通车列表
const normalList = () => {
  return axios.post('/api/trd/level/normalList');
};

// 会员车列表
const memberList = (memberId) => {
  return axios.post(`/api/trd/level/memberList/${memberId}`);
};

export {
  levelList,
  levelInfo,
  levelAddOrEdit,
  levelDelete,
  normalList,
  memberList
};
