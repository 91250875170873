<template>
  <a-modal
    v-model="visible"
    :maskClosable="false"
    title="开单"
    ok-text="确认"
    cancel-text="取消"
    width="1500px"
    :dialog-style="{ top: '10px' }"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form-model
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ span: 2 }"
      :wrapper-col="{ span: 22 }"
    >
      <a-row>
        <a-col>
          <a-form-model-item label="会员卡号" prop="phone">
            <a-input-search
              placeholder="请输入会员卡号/手机号"
              v-model.trim="form.phone"
              @change="handleSearch"
              @pressEnter="handleSearch"
            >
            </a-input-search>
            <div>
              <span class="mr-10">会员卡号：{{ currentMember.userCode }}</span>
              <span class="mr-10"
              >姓名：{{ currentMember.realName || "-" }}</span
              >
              <span class="mr-10"
              >手机号：{{ currentMember.mobile || "-" }}</span
              >
              <span class="mr-10">剩余次数：{{ currentMember.count || 0 }}次</span>
              <span class="mr-10" v-for="item in timesCardPersonList"
                    :key="item.timesCardPersonId">
                {{item.kartClassifyName}}:{{item.count || 0}}次
              </span>
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row v-if="usableVehicleList.length > 0">
        <a-col>
          <a-form-model-item label="请选择" prop="goodsData">
            <a-tabs v-model="tabIndex" @change="handleChangeTabs">
              <a-tab-pane key="1" tab="次卡车辆">
                <ul class="goods__list">
                  <section v-for="item in usableVehicleList"
                           :key="item.kartClassifyId">
                    <li
                      class="goods__item"
                      v-if="item.tempBalance > 0"
                      @contextmenu.prevent.stop="handleAddGoods(item, true)"
                      @click="handleAddGoods(item)"
                    >
                      <div class="car_item">
                        <div
                          class="bill"
                          :style="'background-color:' + item.color"
                        >
                          <span class="bill_tit">{{ item.carCategoryName }}</span>
                        </div>
                        <div
                          class="bill_content"
                          :style="'border:1px solid ' + item.color"
                        >
                          <div class="name">{{ item.mName }}</div>
                          <div class="remarks">
                          <span
                            style="
                              background-color: #f5f5f5;
                              font-size: 13px;
                              padding: 2px 5px;
                              border-radius: 15px;
                              letter-sapcing: 1px;
                            "
                          >{{ item.name }}</span
                          >
                          </div>
                          <div
                            class="validity"
                            :style="'color:' + item.fontColor"
                          >
                            有效期：{{ item.periodTypeName }}
                          </div>
                          <div class="price_num">
                            <div class="price" :style="'color:' + item.color">
                              <div class="price_mem">
                                <div style="line-height: 45px; color: #666">
                                  会员价
                                </div>
                                <div class="price_price">
                                  {{ item.memberPrice }}
                                </div>
                              </div>
                              <div class="price_buy">点击购买</div>
                            </div>
                          </div>

                          <div>
                            <div class="aaa">
                              <div
                                class="bbb"
                                :style="'background-color:' + item.bgColor"
                              >
                              <span :style="'color:' + item.fontColor"
                              >原￥{{ item.price }}</span
                              >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="goods__item__tips">
                        <a-icon style="font-size: 40px" type="shopping-cart" />
                        <p>点击加入</p>
                      </div>
                    </li>
                  </section>
                </ul>
              </a-tab-pane>
            </a-tabs>
            <div class="goods__table">
              <a-table
                size="small"
                bordered
                :pagination="false"
                :scroll="{ y: 150 }"
                :columns="goodsColumns"
                :data-source="form.goodsData"
              >
                <template slot="footer" slot-scope='currentPageData'>
                  <div class="text-right">
                    <span class="mr-10">商品数量：{{ total.num }} </span>
                  </div>
                </template>
              </a-table>
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col>
          <a-form-model-item label="备注" prop="remarks">
            <a-input
              v-model="form.remarks"
              allowClear
              :maxLength="100"
            ></a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <template slot="footer">
      <a-button key="back" @click="handleCancel"> 取消 </a-button>
      <a-button key="submit" type="primary" @click="handleOk">
        去收款
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import ACol from 'ant-design-vue/es/grid/Col';
import { timesCardPersonList, timesCardPersonReduce } from '@/api/timesCard';
import { getByCodeOrMobile } from '@/api/member';
import { vehicleQueryAll } from '@/api/vehicle';
import { timesCardOrderCreate, orderpay } from '@/api/order';
import { filterMultiDictText } from '@/components/dict/JDictSelectUtil';
import { loadCategoryCarData } from '@/api/manage';
import { formatDate } from '@/libs/utils';

export default {
  name: 'CreateTimesCardOrderModal',
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ACol
  },
  data() {
    return {
      timesCardPersonList: [],
      carDictOptions: {},
      carCategoryId: '',
      visible: this.value,
      vehicleRuleList: [],
      usableVehicleList: [],
      currentMember: {},
      form: {
        goodsData: [],
        phone: '',
        type: '',
        remarks: ''
      },
      rules: {
        goodsData: [{ required: true, message: '选择商品', trigger: 'change' }],
        phone: [
          { required: true, message: '请输入会员卡号', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value) {
                if (!this.currentMember.memberId) {
                  callback(new Error('没有找到对应的会员'));
                } else {
                  callback();
                }
              }
              callback();
            },
            trigger: 'blur',
          },
        ],
      },
      goodsColumns: [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          width: 50,
          customRender: (text, row, index) => {
            return index + 1;
          },
        },
        {
          title: '商品名称',
          dataIndex: 'mName',
          key: 'mName',
          width: 100,
        },
        {
          title: '单价',
          dataIndex: 'price',
          key: 'price',
          width: 80,
        },
        {
          title: '库存',
          dataIndex: 'tempBalance',
          key: 'tempBalance',
          width: 80,
          customRender: (text, row) => {
            return isNaN(row.tempBalance) ? '充足' : row.tempBalance;
          },
        },
        {
          title: '数量',
          dataIndex: 'num',
          key: 'num',
          ellipsis: true,
          width: 80,
          customRender: (text, row) => {
            return (
              <section>
                <a-input-number
                  {...{
                    attrs: {
                      min: 1,
                      max: 9999999999,
                      value: text,
                    },
                  }}
                  vOn:change={(val) => this.handleChange(val, row)}
                  style="width: 100%;"
                ></a-input-number>
              </section>
            );
          },
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          width: 80,
          customRender: (text, row, index) => {
            return (
              <section>
                <a-button
                  type="link"
                  onClick={() => this.handleRemoveGoods(row, index)}
                >
                  删除
                </a-button>
              </section>
            );
          },
        },
      ],
      tabIndex: '1',
    };
  },
  computed: {
    total() {
      let num = 0;
      this.form.goodsData.forEach((item) => {
        num += item.num;
      });
      return {
        num,
      };
    }
  },
  watch: {
    'form.goodsData': {
      handler(newVal) {
        console.log('-----form.goodsData-------------');
        // console.log(newVal);
      },
      deep: true,
    },
    value(val) {
      this.visible = val;
      if (val) {
        console.log('进入次卡开单界面');
        this.initAll();
      }
    },
  },
  created() {},
  methods: {
    initAll() {
      console.log('初始化次卡开单数据');
      this.tabIndex = '1';
      this.currentMember = {};
      this.usableVehicleList = [];
      this.timesCardPersonList = [];
      this.getData();
    },
    getData() {
      this.getVehicleRuleList();
      this.initCarDictConfig();
    },
    // 切换标签
    handleChangeTabs() {
      console.log('handleChangeTabs>>>>');
    },
    // 查找会员 13466639190
    handleSearch(e) {
      const searchText = e.target.value;
      getByCodeOrMobile(searchText).then((data) => {
        this.currentMember = data || {};
        // console.log(this.currentMember);
        if (this.currentMember.memberId) {
          timesCardPersonList(this.currentMember.memberId).then((data1) => {
            this.timesCardPersonList = data1;
            if (this.timesCardPersonList != null && this.timesCardPersonList.length > 0) {
              data1.forEach((item) => {
                const rule = this.vehicleRuleList.find(
                  (vehicleRule) => vehicleRule.kartClassifyId === item.kartClassifyId
                );
                if (rule) {
                  const newData = JSON.parse(JSON.stringify(rule));
                  newData.tempBalance = item.count;
                  newData.balance = item.count;
                  this.usableVehicleList.push(newData);
                }
              });
            }
          });
        } else {
          this.usableVehicleList = [];
          this.timesCardPersonList = [];
        }
      });
    },
    // 选择会员
    handleSelect(value, options) {
      const item = options.data.attrs['data-item'];
      this.currentMember = { ...item };
    },

    // 获取计费规则列表
    getVehicleRuleList() {
      const that = this;
      vehicleQueryAll().then((data) => {
        if (data) {
          data.forEach((item) => {
            item.tempBalance = 0;
            item.tempNum = 0;
            item.num = 0;
            item.mName = item.kartClassifyName.slice(0, 8); // 展示图片上的名称
            // item.mName = item.kartClassifyName.slice(0, 4); // 展示图片上的名称
            item.mId = item.kartClassifyId;
            item.name = item.kartClassifySpec.slice(0, 24);
            // item.name = item.kartClassifyName;
            // item.price = item.price; // 已有
            item.carCategoryName = filterMultiDictText(
              that.carDictOptions.carCategoryId,
              item.carCategoryId
            );
            item.carCategoryName = item.carCategoryName.slice(0, 12);
            if (item.periodType === 0) {
              item.periodTypeName = '长期有效';
            } else {
              item.periodTypeName = `${formatDate(
                item.startDate
              )} - ${formatDate(item.endDate)}`;
            }
            item.source = 1;
            item.num = 0;
            item.total = 0;
            // 蓝色 #409dff 浅蓝色 #eaf4ff
            // 橙色 #ff6633 浅橙色 #ffebe5
            // 黄色 #fec22b 浅黄色 #fff4d8
            // 青色 #24c5c0 浅青色 #e8fbfa
            // 绿色 #45be8c 浅绿色 #e1f4ec
            // 紫色 #ae6fda 浅紫色 #f5eafd
            item.bgColor = '#eaf4ff';
            item.fontColor = '#016cd4';
            if (item.color === '#409dff') {
              item.bgColor = '#eaf4ff';
              item.fontColor = '#016cd4';
            } else if (item.color === '#ff6633') {
              item.bgColor = '#ffebe5';
              item.fontColor = '#ff6633';
            } else if (item.color === '#fec22b') {
              item.bgColor = '#fff4d8';
              item.fontColor = '#eca900';
            } else if (item.color === '#24c5c0') {
              item.bgColor = '#e8fbfa';
              item.fontColor = '#24c5c0';
            } else if (item.color === '#45be8c') {
              item.bgColor = '#e1f4ec';
              item.fontColor = '#45be8c';
            } else if (item.color === '#ae6fda') {
              item.bgColor = '#f5eafd';
              item.fontColor = '#ae6fda';
            }
          });
          this.vehicleRuleList = data;
        }
      });
    },
    handleAddGoods(goods, contextmenu = false) {
      if (contextmenu && !goods.num) {
        return;
      }
      const payload = contextmenu ? -1 : 1;
      const item = goods;

      // 商品直接增加，不增加列表数据
      const len = this.form.goodsData.length;
      let flag = false; // 列表中没有
      // 查看是否已经存在了，然后增加
      for (let i = 0; i < len; i++) {
        const goodsItem = this.form.goodsData[i];
        if (!goodsItem) {
          continue;
        }
        if (goodsItem.mId === item.mId) {
          flag = true;
          goodsItem.num += payload;
          goodsItem.tempNum = goodsItem.num;
          goodsItem.tempBalance -= 1;
          console.log(`handleAddGoods:goodsItem.num:${goodsItem.num}`);
          console.log(`handleAddGoods:goodsItem.num:${goodsItem.tempNum}`);
          console.log(
            `handleAddGoods:goodsItem.tempBalance:${goodsItem.tempBalance}`
          );
          if (!goodsItem.num) {
            this.form.goodsData.splice(i, 1);
          }
        }
      }

      if (!flag) {
        item.num = 1;
        item.tempNum = item.num;
        item.tempBalance -= 1;
        console.log(`handleAddGoods:item.num:${item.num}`);
        console.log(`handleAddGoods:item.num:${item.tempNum}`);
        console.log(`handleAddGoods:item.tempBalance:${item.tempBalance}`);
        this.form.goodsData.push(item);
      }

      this.$refs.form.validateField('goodsData');
    },
    handleRemoveGoods(item, index) {
      if (item.num) {
        item.num = 0;
        item.tempNum = item.num;
        item.tempBalance = item.balance;
        console.log(`handleRemoveGoods:item.num:${item.num}`);
        console.log(`handleRemoveGoods:tempNum.num:${item.tempNum}`);
        console.log(`handleRemoveGoods:item.tempBalance:${item.tempBalance}`);
      }

      this.form.goodsData.splice(index, 1);
    },
    handleChange(value, row) {
      if (!isNaN(row.tempBalance)) {
        if (value) {
          if (row.balance - value > 0) {
            row.tempBalance = row.balance - value;
            // row.tempNum = value;
            row.num = value;
            console.log(`输入值比余量少:row.num:${value}`);
            console.log(`输入值比余量少:row.tempBalance:${row.tempBalance}`);
          } else {
            row.tempBalance = 0;
            // row.tempNum = NaN;
            value = row.balance;
            row.num = row.balance;
            console.log(`输入值比余量多:row.tempBalance:${row.tempBalance}`);
          }
        } else {
          row.tempBalance = row.balance;
          row.tempNum = NaN;
          console.log('清空:tempNum为NaN');
          console.log(`清空:${row.tempBalance}`);
        }
      } else {
        row.num = value;
        console.log(`tempBalance:${row.tempBalance}`);
      }
    },
    handleCancel() {
      this.usableVehicleList = [];
      this.$refs.form.resetFields();
      this.currentMember = {};
      this.visible = false;
      this.form.phone = '';
      this.$emit('input', false);
      this.$emit('on-cancel');
    },
    // 提交付款
    handleOk() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const self = this;
          const params = {
            timesCardType: 1, // 次卡订单
            amount: 0, // 订单金额
            amountDiscount: 0, // 优惠金额
            amountPay: 0, // 应付金额
            memberId: this.currentMember.memberId,
            mobile: this.currentMember.mobile,
            remarks: this.form.remarks,
            count: this.total.num
          };

          const karts = [];
          this.form.goodsData.forEach((item) => {
            karts.push({
              kartClassifyId: item.kartClassifyId,
              kartClassifyName: item.kartClassifyName,
              price: 0,
              quantity: item.num,
            });
          });
          // 最终展示数据 ， 创建元素；
          const newDatas = [];
          const h = this.$createElement;
          newDatas.push(h('p', null, `消费总数：${self.total.num}次`));
          newDatas.push(h('p', null, '消费明细：'));
          // 循环数据 依次放入最终数据中
          karts.forEach((item) => {
            newDatas.push(h('p', null, `${item.kartClassifyName}：${item.quantity}次`));
          });
          params.karts = karts;
          this.$confirm({
            title: '次卡消费',
            content: h('div', null, newDatas),
            okType: 'danger',
            onOk() {
              console.log('ok');
              return new Promise((resolve, reject) => {
                timesCardOrderCreate(params)
                  .then((data) => {
                    params.orderId = data.orderId;
                    orderpay({
                      orderId: data.orderId,
                      pays: [{
                        amountPaid: 0, // 支付金额
                        payMethod: 3, // 支付类型 0:现金 1:支付宝 2:微信 3 个人账户 9惠商魔方支付
                        remarks: ''
                      }],
                    }).then(() => {
                      timesCardPersonReduce(params).then(() => {
                        self.$refs.form.resetFields();
                        self.currentMember = {};
                        self.form.phone = '';
                        self.visible = false;
                        self.$emit('input', false);
                        self.$emit('on-ok', data);
                        resolve();
                      });
                    });
                  })
                  .catch(() => {
                    reject();
                  });
              }).catch((e) => console.log(e));
            },
            onCancel() {},
          });
        } else {
          this.$message.error('请按要求填写');
          return false;
        }
      });
    },
    initCarDictConfig() {
      loadCategoryCarData({ code: '0' }).then((res) => {
        console.log('次卡开单界面初始化车辆字典值', res);
        if (res !== undefined) {
          this.$set(this.carDictOptions, 'carCategoryId', res);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../style/modules/variables";

// 优惠券样式开始

.yuan {
  position: absolute;
  top: 48px;
  left: -8px;
  width: 14px;
  height: 14px;
  border-radius: 100px;
  background-color: #fff;
}
.Title_name {
  width: 130px;
  margin-top: 1.5%;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 350;
  color: #333;
  line-height: 1;
}
// 优惠券样式结束

.goods__list {
  display: flex;
  flex-flow: row wrap;
  max-height: 358px;
  overflow: auto;
  border: 1px solid #ddd;
  border-radius: 5px;
}
.goods__item {
  display: flex;
  flex: 0 1 auto;
  position: relative;
  margin: 5px;
  padding: 3px 3px 0;
  text-align: center;
  //border: 1px solid @border-color-base;
  cursor: pointer;
  user-select: none;

  .goods__item__tips {
    display: none;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding-top: 20px;
    line-height: 20px;
    color: #fff;
    background: rgba(24, 144, 255, 0.5);
  }

  &:hover {
    .goods__item__tips {
      display: block;
    }
  }
}

.goods__table {
  margin-top: 10px;
  /*border: 1px solid red;*/
  /*max-height: 260px;*/
}

.goods__item--title,
.goods__item--text {
  width: 74px;
  word-break: break-all;
  font-size: 12px;
  margin: 5px 0;
  user-select: none;

  white-space: normal;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.goods__item--title {
  font-size: 18px;
}
.car_item {
  width: 246px;
  height: 160px;
  margin-right: 0px;
  margin-top: 1%;
  display: flex;
  flex-direction: row;
}

.bill {
  //border: 1px solid red;
  //font-size: 18px;
  //font-weight: 800;
  background-color: #409dff;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  color: #fff;
  display: flex;
  align-items: center;
}

.bill_tit {
  width: 38px;
  height: 126px;
  font-size: 18px;
  font-weight: 900;
  /* background-color: #0000FF; */
  display: flex;
  margin: 0 auto;
  align-items: center;
  vertical-align: center;
  justify-content: center;
  //border: 1px solid red;
  -webkit-writing-mode: vertical-lr;
  writing-mode: vertical-lr;
  text-overflow: ellipsis;
  white-space: overflow;
  overflow: hidden;
  line-height: 1.1;
}

.bill_content {
  width: 300px;
  padding-left: 8px;
  /* padding-right: 4%; */
  border: 1px solid #409dff;
}

.aaa {
  width: 92px;
  height: 120px;
  overflow: hidden;
  position: absolute;
  top: 8px;
  right: 5px;
}

.bbb {
  background-color: #409dff;
  text-align: center;
  font-size: 13px;
  font-weight: 450;

  color: #fff;
  word-spacing: -5px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  position: relative;
  padding: 0;
  left: 8px;
  top: 15px;
  width: 120px;
}
.ccc {
  color: #fff;
  background-color: #409dff;
  font-size: 13px;
  height: 22px;
  line-height: 20px;
  margin-right: 10px;
  display: inline-block;
  padding: 0 6px;
  border-radius: 100px;
}
.name {
  font-size: 19px;
  font-weight: 600;
  color: #333;
  margin-top: 2%;
  line-height: 36px;
  text-align: left;
}

.remarks {
  width: 163px;
  height: 38px;
  margin-top: 3px;
  display: block;
  //display: inline-block;
  //background-color: #f9f9f9;
  color: #8c8c8c;
  text-align: left;
}

.price_num {
  display: flex;
  float: row;
  justify-content: space-between;
}

.price {
  padding: 3px 0 0 0;
  font-size: 14px;
  color: #0383ff;
  display: flex;
  flex-direction: row;
}

.price_mem {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.price_price {
  line-height: 40px;
  font-size: 26px;
  letter-spacing: -2px;
  font-weight: bold;
}
.price_buy {
  height: 22px;
  position: absolute;
  top: 125px;
  right: 12px;
  border: 1px solid #d0d0d0;
  color: #666;
  font-weight: 250;
  border-radius: 30px;
  padding: 0 7px 0 7px;
  font-size: 13px;
}

.validity {
  color: #9c9c9c;
  // margin: 9px 0 0 0;
  padding: 0;
  text-align: left;
  font-size: 8px;
  line-height: 1;
  letter-spacing: -0.4px;
  width: auto;
}

.price_big {
  font-size: 26px;
  font-weight: 800;
  letter-spacing: -1px;
}

.price_zero {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -1px;
}

.addto {
  width: 100px;
  margin-top: 10px;
  margin-left: 10px;
  height: 30px;
  line-height: 28px;
  text-align: center;
  color: #333333;
  font-size: 15px;
  font-weight: 500;
  border: 1px solid #d0d0d0;
  border-radius: 100px;
}

.choose {
  width: 100%;
  height: 800px;
  display: flex;
  flex-direction: row;
}
.couponCard {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  overflow-x: auto;
  margin-bottom: 20px;
}
.couponCardItem {
  flex-shrink: 0;
  min-width: 300px;
  margin-right: 20px;
  height: 110px;
  display: flex;
  flex-direction: row;
  position: relative;
  border: 1px solid red;
  border-radius: 10px;
  margin-bottom: 20px;
  .bbb {
    background: red;
  }
  .left {
    border-radius: 7px 0 0 7px;
    width: 100px;
    background: red;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    .l-inner {
      text-align: center;
      .l1 {
        span {
          font-size: 39px;
        }
      }
    }
  }
  .right {
    padding: 10px;
    position: relative;
    flex: 1;
    .name {
      font-size: 16px;
    }
    .indate {
      color: #adadad;
      font-size: 13px;
      margin-top: 6px;
    }
    .isShare {
      position: absolute;
      line-height: 35px;
      bottom: 0;
    }
  }
  .float-text {
    position: absolute;
    right: -6px;
    top: -8px;
  }
  .checked-ouline {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 34px;
    color: #ae6fda;
  }
}
.type-1 {
  border: 2px solid #ae6fda;
  .left {
    background: #ae6fda;
  }
  .bbb {
    background: #ae6fda;
  }
  .ccc {
    background: #ae6fda;
  }
  .checked-ouline {
    color: #ae6fda;
  }
}
.type-2 {
  border: 2px solid #ff6633;
  .left {
    background: #ff6633;
  }
  .bbb {
    background: #ff6633;
  }
  .ccc {
    background: #ff6633;
  }
  .checked-ouline {
    color: #ff6633;
  }
}
.type-3 {
  border: 2px solid #24c5c0;
  .left {
    background: #24c5c0;
  }
  .bbb {
    background: #24c5c0;
  }
  .ccc {
    background: #24c5c0;
  }
  .checked-ouline {
    color: #24c5c0;
  }
}
.type-4 {
  border: 2px solid #409dff;
  .left {
    background: #409dff;
  }
  .bbb {
    background: #409dff;
  }
  .checked-ouline {
    color: #409dff;
  }
}
.is-used {
  background: #cfcfcf;
  border: 2px solid #cfcfcf;
  .left {
    background: #959595;
  }
  .bbb {
    background: #959595;
  }
}
</style>
