<template>
  <a-modal
    v-model="visible"
    :maskClosable="false"
    title="开单"
    ok-text="确认"
    cancel-text="取消"
    width="1500px"
    :dialog-style="{ top: '10px' }"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form-model
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ span: 2 }"
      :wrapper-col="{ span: 22 }"
    >
      <a-row v-if="form.isMember === 1">
        <a-col>
          <a-form-model-item label="客户类型">
            会员
            <div>
              <span class="mr-10">会员卡号：{{ currentMember.userCode }}</span>
              <span class="mr-10"
                >姓名：{{ currentMember.realName || "-" }}</span
              >
              <span class="mr-10"
                >手机号：{{ currentMember.mobile || "-" }}</span
              >
              <span class="mr-10">余额：{{ currentMember.amount || 0 }}元</span>
              <span class="mr-10"
                >折扣比：{{ currentMember.discount || 1 }}</span
              >
              <span v-if="isYear"> 年卡结束时间：{{ currentMember.expiryTime }}</span>
              <span v-if="isSHow" :style="'color:' + endTimeColor"
                > 结束时间：{{ currentMember.endTime }}</span
              >
              <span v-if="isRecharge" :style="'color:' + rechargeTimeColor"
              >&nbsp;上次充值时间：{{ currentMember.updateTime }}</span
              >
            </div>
            <div style="color: red">
              <span>{{ currentMember.feeTip || '' }}</span>
              <span>{{ currentMember.feeTime || '' }}</span>
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row v-else>
        <a-col>
          <a-form-model-item label="客户类型">
            散客
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col>
          <a-form-model-item label="请选车" prop="goodsData">
            <div v-for="item in preMatchList" :key="item.id">
              <div>场次：{{item.matchName}} 场</div>
              <ul class="preCarList">
                <li
                  class="preCarItem" v-for="carInfo in item.preCarInfoList" :key="carInfo.id"
                >
                  <section v-if="carInfo.status == 'free'">
                    <div
                      class="preCarCardItem"
                      @click="handleChooseTimesCardRule(carInfo)"
                    >
                      <div class="preCarName">{{carInfo.carName}}</div>
                      <div class="preCarChecked" v-if="!!carInfo.checked">
                        <a-icon type="check-circle" />
                      </div>
                    </div>
                  </section>
                  <section v-else>
                    <div
                      class="selectedCarCardItem"
                    >
                      <div class="preCarName">{{carInfo.carName}}</div>
                    </div>
                  </section>

                </li>
              </ul>
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>
      <!-- memberFlag-->
      <template v-if="memberFlag === 2">
        <a-row>
          <a-col>
            <a-form-model-item label="是否会员" prop="isMember">
              <a-radio-group
                button-style="solid"
                v-model="form.isMember"
                @change="handleIsMember"
              >
                <a-radio-button :value="2"> 否 </a-radio-button>
                <a-radio-button :value="1"> 是 </a-radio-button>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row v-if="form.isMember === 1">
          <a-col>
            <a-form-model-item label="会员卡号" prop="phone">
              <a-input-search
                placeholder="请输入会员卡号/手机号"
                v-model.trim="form.phone"
                @change="handleSearch"
                @pressEnter="handleSearch"
              >
              </a-input-search>
              <div>
                <span class="mr-10">会员卡号：{{ currentMember.userCode }}</span>
                <span class="mr-10"
                >姓名：{{ currentMember.realName || "-" }}</span
                >
                <span class="mr-10"
                >手机号：{{ currentMember.mobile || "-" }}</span
                >
                <span class="mr-10">余额：{{ currentMember.amount || 0 }}元</span>
                <span class="mr-10"
                >折扣比：{{ currentMember.discount || 1 }}</span
                >
                <span v-if="isYear"> 年卡结束时间：{{ currentMember.expiryTime }}</span>
                <span v-if="isSHow" :style="'color:' + endTimeColor"
                > 结束时间：{{ currentMember.endTime }}</span
                >
                <span v-if="isRecharge" :style="'color:' + rechargeTimeColor"
                >&nbsp;上次充值时间：{{ currentMember.updateTime }}</span
                >
              </div>
              <div style="color: red">
                <span>{{ currentMember.feeTip || '' }}</span>
                <span>{{ currentMember.feeTime || '' }}</span>
              </div>
              <a-row>
                <a-col>
                  <img v-if="currentMember.timePicture" :src="currentMember.timePicture">
                </a-col>
              </a-row>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row v-if="form.isMember === 1 && currentMember.memberId && discountCardList.length >0">
          <a-col>
            <a-form-model-item label="会员折扣比" prop="discountCard">
              <a-select
                v-model="selectedDiscount"
                allowClear
                @change="handleChangeDiscountCard"
              >
                <a-select-option
                  v-for="item in discountCardList"
                  :key="item.discountCardId"
                >{{ item.discount }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
      </template>
      <!-- memberFlag-->
      <a-row
        style="padding-left: 121px"
        v-if="!!currentCouponRecords && currentCouponRecords.length > 0"
      >
        <div class="couponCard">
          <template v-for="item in currentCouponRecords">
            <div
              class="couponCardItem isChecked"
              :class="{
                'is-used': !!item.used,
                'type-1': +item.types === 1,
                'type-2': +item.types === 2,
                'type-3': +item.types === 3,
                'type-4': +item.types === 4,
              }"
              @click="handleChooseCurrentCoupon(item)"
            >
              <div class="yuan"></div>
              <div class="left">
                <div class="l-inner">
                  <div class="l1">
                    ￥<span style="font-weight: bold; line-height: 1">{{
                      item.cash
                    }}</span>
                  </div>
                  <div class="l2" style="margin-top: 8px">
                    {{
                      item.conditioncash > 0
                        ? "满 " + item.conditioncash + " 元可用"
                        : "无金额限制"
                    }}
                  </div>
                </div>
              </div>
              <div class="right">
                <!-- <div class="name">{{ item.couponName }}</div> -->
                <div style="display: flex; flex-direction: row">
                  <div class="ccc">{{ dictCouponType(item.types) }}</div>
                  <div class="Title_name">{{ item.couponName }}</div>
                </div>
                <div class="indate">{{ computedIndate(item) }}</div>
                <div class="isShare">
                  {{
                    item.sharecnt > 1
                      ? "可叠加(" + item.sharecnt + "次)"
                      : "不可叠加"
                  }}
                </div>
              </div>
              <div class="checked-ouline" v-if="!!item.checked">
                <a-icon type="check-circle" />
              </div>
            </div>
          </template>
        </div>
      </a-row>
      <a-row v-has="'order:discount'">
        <a-col>
          <a-form-model-item label="优惠规则" prop="discountWay">
            <a-select v-model="form.discountWay">
              <a-select-option :value="0"> 无 </a-select-option>
              <a-select-option :value="1"> 立减 </a-select-option>
            </a-select>
            <span v-if="form.discountWay === 1"
              ><a-input-number
                :min="0"
                :max="total.price"
                v-model="form.fee"
              ></a-input-number>
              元</span
            >
          </a-form-model-item>
        </a-col>
      </a-row>
      <div v-if="promotionList.length > 0">
        <a-row>
          <a-col>
            <a-form-model-item label="同行立减活动" prop="promotion">
              <a-select
                v-model="form.promotion"
                allowClear
                placeholder="请选择"
              >
                <a-select-option
                  v-for="item in promotionList"
                  :key="item.promotionId"
                  :value="item.promotionRule"
                >
                  {{ item.promotionName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
      </div>
      <a-row>
        <a-col>
          <a-form-model-item label="备注" prop="discountWayRamarks">
            <a-input
              v-model="form.discountWayRamarks"
              allowClear
              :maxLength="100"
            ></a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <template slot="footer">
      <span class="mr-10"> 优惠金额：¥ {{ bill.discount }} </span>
      <span class="mr-10 text-bold primary-color">
        实付金额：¥ {{ bill.pay }}
      </span>
      <a-button key="back" @click="handleCancel"> 取消 </a-button>
      <a-button key="submit" type="primary" @click="handleOk">
        去收款
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import moment from "moment";
import ACol from "ant-design-vue/es/grid/Col";
import { orderCreate } from "@/api/order";
import { getMemberCouponUsedByMemberId, checkHoliday } from "@/api/coupon";
import { promotionList } from "@/api/promotion";
import { preMatchList, checkCar, updateCarStatus } from "@/api/preMatch";
import { getByCodeOrMobile, queryEffectiveByMobile } from "@/api/member";

export default {
  name: 'CreateDiySelectCarModal',
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ACol,
  },
  data() {
    return {
      discountCardList: [],
      discountCardValue: 1,
      selectedDiscount: 1,
      isYear: false,
      isSHow: false,
      isRecharge: false,
      rechargeTimeColor: 'black',
      endTimeColor: 'black',
      carDictOptions: {},
      dictOptions: {},
      carCategoryId: '',
      categoryId: '',
      visible: this.value,
      discountList: [],
      memberList: [],
      vehicleRuleList: [],
      goodsList: [],
      groupGoodsList: [],
      groupVehicleList: [],
      currentMember: {},
      memberFlag: 2,
      promotionList: [],
      preMatchList: [],
      selectedIdList: [],
      form: {
        goodsData: [],
        phone: '', // type rule pay discountWayRamarks
        type: '',
        discountWay: 0, // 无优惠 1是立减
        isMember: 2, // 是否会员
        fee: 0, // 立减金额
        pay: '',
        discountWayRamarks: '',
        promotion: null,
        // 使用的优惠卷ids
        couponids: '',
      },
      rules: {
        goodsData: [{ required: true, message: '选择商品', trigger: 'change' }],
        fee: [{ required: true, message: '请输入立减金额', trigger: 'blur' }],
      },
      currentCouponTotal: 0,
      currentCouponRecords: [],
      currentCouponRecordsBack: [],
      // 选择的优惠卷
      selectCoupons: [],
      CouponSum: 0,
      isHoliday: false,
    };
  },
  computed: {
    total() {
      const that = this;
      let price = 0;
      let num = 0;
      let amnt = 0;
      let free = 0;
      if (this.form.isMember === 1) {
        this.form.goodsData.forEach((item) => {
          // console.log(this.currentMember.discount)
          num += item.num;
          amnt = item.price * item.num;
          console.log(`amnt${amnt}`);
          console.log(`free${free}`);
          if (this.currentMember.expiryTime != null) {
            price = 0;
            free += item.price * item.num;
          }
          else {
            if (that.$store.getters.getStoreInfo.feeSwitch === 1) {
              if (item.discountFlag === 1) {
                console.log('会费商品不享受会员折扣');
                price += item.memberPrice * item.num;
                free += (item.price - item.memberPrice) * item.num;
              } else {
                if (this.currentMember.feeEndTime != null) {
                  console.log('充值过会费，判断是否到期');
                  const diffDays = this.calDiffDay(this.currentMember.feeEndTime);
                  console.log(diffDays);
                  if (diffDays > 0) {
                    console.log('会费到期');
                    price += item.memberPrice * item.num;
                    free += (item.price - item.memberPrice) * item.num;
                  } else {
                    console.log('会费未到期');
                    price += item.memberPrice * this.currentMember.feeDiscount * item.num;
                    free += (item.price - item.memberPrice * this.currentMember.feeDiscount) * item.num;
                  }
                } else {
                  console.log('未充值过会费');
                  price += item.memberPrice * item.num;
                  free += (item.price - item.memberPrice) * item.num;
                }
              }
            }
            else {
              if (this.currentMember.discount && this.currentMember.discount < 1) {
                console.log("会员折扣");
                // 如果商品折扣标志开关为开，则打折，0关 1开
                console.log(item.discountFlag)
                if (item.discountFlag === 1) {
                  console.log('商品不享受会员折扣');
                  price += item.memberPrice * item.num;
                  free += (item.price - item.memberPrice) * item.num;
                } else {
                  console.log('享受会员折扣');
                  price += item.memberPrice * this.currentMember.discount * item.num;
                  free += (item.price - item.memberPrice * this.currentMember.discount) * item.num;
                }
              } else {
                if (item.discount) {
                  console.log("车辆折扣");
                  // price += (item.price * item.discount) * item.num;
                  // free += (item.price - (item.price * item.discount)) * item.num;
                  price += item.memberPrice * item.discount * item.num;
                  free
                    += (item.price - item.memberPrice * item.discount) * item.num;
                } else {
                  price += item.price * item.num;
                  free += (item.price - item.memberPrice) * item.num;
                }
              }
            }
          }
        });
        console.log("ismember");
        if (this.form.goodsData.length > 0) {
          const { memberPrice } = this.form.goodsData[0];
          const { mId } = this.form.goodsData[0];
          let only = true;
          for (let i = 0; i < this.form.goodsData.length; i++) {
            if (mId !== this.form.goodsData[i].mId) {
              only = false;
              break;
            }
          }
          if (!only) {
            if (this.form.promotion != null) {
              this.form.promotion = null;
            }
          } else {
            if (this.form.promotion != null) {
              const arr = this.form.promotion.split("-");
              const freeNum =
                Math.floor(num / parseInt(arr[0])) * parseInt(arr[1]);
              price -= freeNum * memberPrice;
              free += freeNum * memberPrice;
            }
          }
          // 优惠劵使用逻辑
          if (this.selectCoupons && this.selectCoupons.length > 0) {
            let totalCouponPrice = 0;
            //
            if (this.selectCoupons.length === 1) {
              this.selectCoupons.forEach((item) => {
                //体验卷
                if (item.types == 1) {
                  for (var s = 0; s < this.form.goodsData.length; s++) {
                    var sou = this.form.goodsData[s];
                    if (sou.source == 1) {
                      totalCouponPrice += sou.price;
                      this.CouponSum = totalCouponPrice;
                    }
                  }
                } else totalCouponPrice += item.cash;
              });
            } else
              this.selectCoupons.forEach((item) => {
                totalCouponPrice += item.cash;
              });
            console.log(totalCouponPrice);
            if (totalCouponPrice >= price) {
              free += price;
              price = 0;
            } else {
              free += totalCouponPrice;
              price -= totalCouponPrice;
            }
          }
          // 优惠劵使用逻辑
        }
      }
      else {
        this.form.goodsData.forEach((item) => {
          num += item.num;
          price += item.price * item.num;
          amnt = price;
        });
        // console.log("notmember");
        if (this.form.goodsData.length > 0) {
          const oralPrice = this.form.goodsData[0].price;
          const { mId } = this.form.goodsData[0];
          let only = true;
          for (let i = 0; i < this.form.goodsData.length; i++) {
            if (mId !== this.form.goodsData[i].mId) {
              only = false;
              break;
            }
          }
          if (!only) {
            if (this.form.promotion != null) {
              this.form.promotion = null;
            }
          } else {
            if (this.form.promotion != null) {
              const arr = this.form.promotion.split("-");
              const freeNum =
                Math.floor(num / parseInt(arr[0])) * parseInt(arr[1]);
              price -= freeNum * oralPrice;
              free += freeNum * oralPrice;
            }
          }
        }
      }
      return {
        price,
        num,
        amnt,
        free,
      };
    },
    bill() {
      // 优惠金额 = 合计 * 折扣（无1，立减 100， 打折 1-10），
      // 应付金额 = 合计 - 优惠
      let pay = 0;
      let discount = 0;
      if (this.form.discountWay === 1) {
        pay = this.total.price - this.form.fee;
        discount = this.total.free * 1 + this.form.fee;
      } else {
        pay = this.total.price * 1;
        discount = this.total.free * 1;
      }

      pay = pay.toFixed(2);
      discount = discount.toFixed(2);
      return {
        discount,
        pay,
      };
    },
  },
  watch: {
    'form.goodsData': {
      handler(newVal) {
        console.log('-----form.goodsData-------------', newVal);
      },
      deep: true,
    },
    value(val) {
      this.visible = val;
      if (val) {
        // 初始化信息放这执行
        console.log('进入自助机选座界面');
        this.initAll();
      }
    },
  },
  created() {
    // 这个地方啥也不干，调试时看看效果
    // console.log('自助机车辆选择界面 created');
  },
  methods: {
    handleChooseTimesCardRule(item) {
      // console.log(item);
      if (item.checked) {
        this.$set(item, 'checked', false);
        this.form.goodsData[0].num --;
        this.selectedIdList.splice(this.selectedIdList.indexOf(item.id), 1);
        console.log('取消');
        // console.log(this.selectedIdList);
      } else {
        this.$set(item, 'checked', true);
        console.log('选中');
        this.form.goodsData[0].num ++;
        this.selectedIdList.push(item.id);
        // console.log(this.selectedIdList);
      }
    },
    // 根据车辆类别ID查询预场次信息
    queryPreMatchInfo() {
      console.log('查询预场次信息');
      preMatchList({ carCategoryId: this.carCategoryId }).then((data) => {
        if (data) {
          console.log(data);
          this.preMatchList = data;
        }
      });
    },
    // 初始化数据
    initAll() {
      console.log('初始化自助机门店选车数据');
      // 初始化信息
      console.log('传输过来的数据', this.data);
      // 初始化商品信息
      this.form.goodsData = [];
      // 重置优惠劵信息
      this.resetCoupon();
      const {
        goodsInfo, currentMember, isMember, isYear, isSHow, isRecharge, carCategoryId
      } = this.data;
      console.log(goodsInfo);
      console.log(`车辆类别ID${carCategoryId}`);
      this.carCategoryId = carCategoryId;
      this.form.isMember = isMember;
      this.memberFlag = isMember;
      this.currentMember = currentMember;
      this.isYear = isYear;
      this.isSHow = isSHow;
      this.isRecharge = isRecharge;
      // 根据车辆类别ID查询预场次信息
      this.queryPreMatchInfo();
      if (this.currentMember.memberId) {
        this.getMemebrCouponListRequest();
      } else {
        // 重置优惠劵信息
        this.resetCoupon();
      }
      goodsInfo.num = 0;
      this.selectedIdList = [];
      this.form.goodsData.push(goodsInfo);
      this.getPromotionList();
    },
    // 计算到期时间
    calDiffDay(date2) {
      const todayDate = moment(new Date());
      const endDate = moment(date2).format('YYYY-MM-DD');
      return todayDate.diff(endDate, 'days');
    },
    // 获取今天是否节假日
    getcheckHoliday() {
      checkHoliday().then((res) => {
        if (res.length > 0) this.isHoliday = true;
        else this.isHoliday = false;
      });
    },
    filterMemberCoupon() {
      this.currentCouponRecords = [];
      if (this.currentCouponRecordsBack.length == 0) return;
      //
      var iCnt = 0;
      var iCarCnt = 0;
      for (let k = 0; k < this.form.goodsData.length; k++) {
        const kitem = this.form.goodsData[k];
        if (kitem.source !== 1) continue;
        iCnt++;
        iCarCnt = Number(iCarCnt) + Number(kitem.num);
      }
      const vecSet = new Set();
      for (let k = 0; k < this.form.goodsData.length; k++) {
        const kitem = this.form.goodsData[k];
        if (kitem.source !== 1) continue;
        const { carCategoryId } = kitem;
        for (let i = 0; i < this.currentCouponRecordsBack.length; i++) {
          const item = this.currentCouponRecordsBack[i];
          const datestr = moment(new Date()).format("YYYY-MM-DD");
          if (item.dayFlag === 0 && item.startTime === datestr) {
            console.log(`当天不可用：${datestr}`);
            continue;
          }
          if (this.isHoliday && item.holidayFlag === 0) {
            console.log(`节假日不能使用：${item.couponName}`);
            continue;
          }
          // 过期的不能用
          if (item.endTime < datestr) {
            continue;
          }
          // 屏蔽体验卷
          if ((iCnt > 1 || iCarCnt > 1) && item.types === 1) {
            console.log("体验卷");
            continue;
          }
          // 转增卷不能在这里消费
          if (item.types === 3 && item.shareStatus !== 2) {
            continue;
          }
          // 充值券不能在这里消费
          if (item.types === 5) {
            continue;
          }
          const ipos = item.userange.indexOf(carCategoryId);
          if (ipos >= 0) {
            // continue;
            vecSet.add(item);
          }
        }
      }
      // 一起处理
      for (const val of vecSet.values()) {
        this.currentCouponRecords.push(val);
      }
    },
    // 获取会员下的优惠券
    getMemebrCouponListRequest() {
      const that = this;
      const _param = {
        memberid: this.currentMember.memberId,
      };
      getMemberCouponUsedByMemberId(_param).then((res) => {
        that.currentCouponRecordsBack = res;
        that.filterMemberCoupon();
      });
    },
    // 提交付款
    handleOk() {
      if (this.form.goodsData[0].num > 0) {
        this.$refs.form.validate((valid) => {
          if (valid) {
            checkCar({ idList: this.selectedIdList }).then((result) => {
              console.log(result);
              if (result) {
                const params = {
                  amount: this.total.price, // 订单金额
                  amountDiscount: this.bill.discount, // 优惠金额
                  amountPay: this.bill.pay, // 应付金额
                  discountWay: this.form.discountWay, // 优惠方式 0:无优惠 根据订单类型 优惠方式不一样
                  discountWayRamarks: this.form.discountWayRamarks, // 优惠方式备注
                  memberId: this.currentMember.memberId,
                  mobile: this.currentMember.mobile,
                  couponids: this.form.couponids,
                  remarks: '',
                };

                const karts = [];
                const products = [];
                this.form.goodsData.forEach((item) => {
                  if (item.source === 1) {
                    karts.push({
                      kartClassifyId: item.kartClassifyId,
                      price: item.price,
                      quantity: item.num,
                    });
                  } else {
                    products.push({
                      productId: item.mId,
                      quantity: item.num,
                    });
                  }
                });
                params.karts = karts;
                params.products = products;
                orderCreate(params).then((data) => {
                  console.log(data);
                  // 更新座位为锁定状态
                  updateCarStatus({ orderId: data.orderId, idList: this.selectedIdList }).then(() => {
                    data.selectedIdList = this.selectedIdList;
                    this.$refs.form.resetFields();
                    this.currentMember = {};
                    this.form.phone = '';
                    this.form.fee = 0;
                    this.visible = false;
                    this.$emit('input', false);
                    this.$emit('on-ok', data);
                  });
                });
              } else {
                this.$message.error('请重新选座');
                this.queryPreMatchInfo();
                this.form.goodsData[0].num = 0;
                this.selectedIdList = [];
                return false;
              }
            });
          } else {
            this.$message.error('请按要求填写');
            return false;
          }
        });
      } else {
        this.$message.error('请选择车辆');
        return false;
      }
    },
    handleCancel() {
      console.log('自助机订单取消');
      this.categoryId = '';
      this.isYear = false;
      this.groupGoodsList = [];
      this.groupVehicleList = [];
      this.selectedIdList = [];
      this.$refs.form.resetFields();
      this.currentMember = {};
      this.form.fee = 0;
      this.visible = false;
      this.form.phone = '';
      this.$emit('input', false);
      this.$emit('on-cancel');
    },
    computedIndate(item) {
      const temp = `${item.startTime}至${item.endTime}`;
      return temp;
    },
    dictCouponType(type) {
      const typeOption = [
        { label: '体验券', value: 1 },
        { label: '优惠券', value: 2 },
        { label: '转赠券', value: 3 },
        { label: '礼包', value: 4 },
        { label: '充值券', value: 5 },
      ];
      return typeOption.filter((item) => {
        return item.value === +type;
      })[0].label;
    },
    handleChooseCurrentCoupon(item) {
      //
      const that = this;
      let MaxShareCnt = 99999;
      let bFind = false;
      for (let i = 0; i < this.selectCoupons.length; i++) {
        const tmp = this.selectCoupons[i];
        if (item.id == tmp.id) {
          bFind = true;
          this.$set(item, "checked", false);
          this.selectCoupons.splice(i, 1);
          break;
        }
      }
      if (this.selectCoupons.length == 0 && !bFind) {
        // 判断使用条件
        if (item.conditioncash > 0 && this.total.amnt < item.conditioncash) {
          this.$message.warning(`该优惠劵满${item.conditioncash}才可以使用`);
          return;
        }
        this.$set(item, "checked", true);
        this.selectCoupons.push(item);
      } else {
        if (!bFind) {
          // 需要添加，则需要看看叠加次数是否超过,找到最小的。
          for (let i = 0; i < this.selectCoupons.length; i++) {
            const tmp = this.selectCoupons[i];
            if (tmp.sharecnt < MaxShareCnt) MaxShareCnt = tmp.sharecnt;
          }
          if (
            this.selectCoupons.length < MaxShareCnt &&
            item.sharecnt > this.selectCoupons.length
          ) {
            // 判断使用条件
            if (
              item.conditioncash > 0 &&
              this.total.amnt < item.conditioncash
            ) {
              this.$message.warning(
                `该优惠劵满${item.conditioncash}才可以使用`
              );
              return;
            }
            this.$set(item, "checked", true);
            this.selectCoupons.push(item);
          } else this.$message.warning("已超过叠加次数，不能同时使用");
        }
      }
      //
      this.form.couponids = "";
      this.CouponSum = 0;
      for (let i = 0; i < this.selectCoupons.length; i++) {
        var item = this.selectCoupons[i];
        this.CouponSum += item.cash;
        if (that.form.couponids == "") that.form.couponids = item.id;
        else that.form.couponids = `${that.form.couponids},${item.id}`;
      }
      console.log(`handleChooseCurrentCoupon:${this.selectCoupons.length}`);
    },
    // 同行立减规则
    getPromotionList() {
      promotionList().then((data) => {
        if (data) {
          this.promotionList = data;
        }
      });
    },
    // 重置优惠劵信息
    resetCoupon() {
      this.currentMember.memberId = '';
      this.currentCouponRecords = [];
      this.selectCoupons = [];
      this.CouponSum = 0;
      this.form.couponids = '';
    },
    handleIsMember() {
      console.log('== handleIsMember1 ========');
      if (this.form.isMember === 2) {
        // 重置优惠劵信息
        this.resetCoupon();
      } else if (this.form.isMember === 1) {
        if (this.currentMember.memberId) {
          this.getMemebrCouponListRequest();
        }
        if (this.form.phone) {
          this.handleSearch1(this.form.phone);
        }
      }
    },
    // 查找会员 13466639190
    handleSearch(e) {
      const searchText = e.target.value;
      getByCodeOrMobile(searchText).then((data) => {
        this.currentMember = data || {};
        if (this.currentMember.memberId) {
          console.log('会员信息');
          console.log(this.currentMember);
          this.queryDiscountCardList();
          // console.log('这个地方查回来会员等级对应的车辆，合并');
          // memberList(this.currentMember.memberId).then((res) => {
          //   // 查到的会员车信息
          //   console.log('查到的会员车信息');
          //   console.log(res);
          //   if (res && res.length > 0) {
          //     console.log('合并会员车信息');
          //     this.mergerList = this.baseNormalList.concat(res);
          //     console.log(this.mergerList);
          //   } else {
          //     console.log('会员车信息为空');
          //     this.mergerList = this.baseNormalList;
          //   }
          //   this.resetMEMBER();
          // });
          if (this.currentMember.expiryTime != null) {
            this.isYear = true;
          }
          const todayDate = moment(new Date());
          // 如果是会费会员，则判断是否在有效期类
          if (this.$store.getters.getStoreInfo.feeSwitch === 1) {
            if (this.currentMember.feeEndTime != null) {
              const feeStartTime = moment(this.currentMember.feeStartTime).format('YYYY-MM-DD');
              const feeEndTime = moment(this.currentMember.feeEndTime).format('YYYY-MM-DD');
              const diffDays = todayDate.diff(feeEndTime, 'days');
              console.log(diffDays);
              if (diffDays > 0) {
                this.currentMember.feeTip = '会员已到期';
              } else {
                this.currentMember.feeTime = `开始时间：${feeStartTime}-结束时间：${feeEndTime}，剩余：${Math.abs(diffDays)}天，会费折扣率：${this.currentMember.feeDiscount}`;
              }
            } else {
              this.currentMember.feeTip = '无效会员或会员已到期';
            }
          } else {
            if (this.currentMember.updateTime != null) {
              this.isRecharge = true;
              const updateTime = moment(this.currentMember.updateTime);
              const diffDays = todayDate.diff(updateTime, 'days');
              console.log(diffDays);
              if (diffDays > 30) {
                this.rechargeTimeColor = 'red';
              }
            }
            if (this.currentMember.endTime != null) {
              this.isSHow = true;
              const v1 = moment(this.currentMember.endTime).format(
                'YYYY-MM-DD 23:59:59'
              );
              const v2 = moment(new Date()).format('YYYY-MM-DD 23:59:59');
              console.log(v1);
              console.log(v2);
              const isBefore = moment(v1).isBefore(moment(v2));
              if (isBefore) {
                this.endTimeColor = 'red';
              }
            }
          }
          this.getMemebrCouponListRequest();
        } else {
          // 重置优惠劵信息
          this.resetCoupon();
          this.isYear = false;
          this.isSHow = false;
          this.endTimeColor = 'black';
          this.isRecharge = false;
          this.rechargeTimeColor = 'black';
          this.groupVehicleList = [];
        }
      });
    },
    // 查找会员 13466639190
    handleSearch1(e) {
      getByCodeOrMobile(e).then((data) => {
        this.currentMember = data || {};
        if (this.currentMember.memberId) {
          console.log('会员信息');
          console.log(this.currentMember);
          this.queryDiscountCardList();
          // console.log('这个地方查回来会员等级对应的车辆，合并');
          // memberList(this.currentMember.memberId).then((res) => {
          //   // 查到的会员车信息
          //   console.log('查到的会员车信息');
          //   console.log(res);
          //   if (res && res.length > 0) {
          //     console.log('合并会员车信息');
          //     this.mergerList = this.baseNormalList.concat(res);
          //     console.log(this.mergerList);
          //   } else {
          //     console.log('会员车信息为空');
          //     this.mergerList = this.baseNormalList;
          //   }
          //   this.resetMEMBER();
          // });
          if (this.currentMember.expiryTime != null) {
            this.isYear = true;
          }
          const todayDate = moment(new Date());
          // 如果是会费会员，则判断是否在有效期类
          if (this.$store.getters.getStoreInfo.feeSwitch === 1) {
            if (this.currentMember.feeEndTime != null) {
              const feeStartTime = moment(this.currentMember.feeStartTime).format('YYYY-MM-DD');
              const feeEndTime = moment(this.currentMember.feeEndTime).format('YYYY-MM-DD');
              const diffDays = todayDate.diff(feeEndTime, 'days');
              console.log(diffDays);
              if (diffDays > 0) {
                this.currentMember.feeTip = '会员已到期';
              } else {
                this.currentMember.feeTime = `开始时间：${feeStartTime}-结束时间：${feeEndTime}，剩余：${Math.abs(diffDays)}天，会费折扣率：${this.currentMember.feeDiscount}`;
              }
            } else {
              this.currentMember.feeTip = '无效会员或会员已到期';
            }
          } else {
            if (this.currentMember.updateTime != null) {
              this.isRecharge = true;
              const updateTime = moment(this.currentMember.updateTime);
              const diffDays = todayDate.diff(updateTime, 'days');
              console.log(diffDays);
              if (diffDays > 30) {
                this.rechargeTimeColor = 'red';
              }
            }
            if (this.currentMember.endTime != null) {
              this.isSHow = true;
              const v1 = moment(this.currentMember.endTime).format(
                'YYYY-MM-DD 23:59:59'
              );
              const v2 = moment(new Date()).format('YYYY-MM-DD 23:59:59');
              console.log(v1);
              console.log(v2);
              const isBefore = moment(v1).isBefore(moment(v2));
              if (isBefore) {
                this.endTimeColor = 'red';
              }
            }
          }
          this.getMemebrCouponListRequest();
        } else {
          // 重置优惠劵信息
          this.resetCoupon();
          this.isYear = false;
          this.isSHow = false;
          this.endTimeColor = 'black';
          this.isRecharge = false;
          this.rechargeTimeColor = 'black';
          this.groupVehicleList = [];
        }
      });
    },
    // 查找会员折扣卡
    queryDiscountCardList() {
      this.discountCardValue = this.currentMember.discount;
      this.selectedDiscount = this.discountCardValue;
      queryEffectiveByMobile(this.currentMember.mobile).then((data) => {
        this.discountCardList = data;
        if (this.discountCardList.length > 0) {
          if (this.discountCardList[0].discount < this.discountCardValue) {
            this.selectedDiscount = this.discountCardList[0].discount;
            this.currentMember.discount = this.selectedDiscount;
          }
        }
        console.log(this.discountCardList);
      });
    },
    // 选择折扣卡
    handleChangeDiscountCard(discountCardId) {
      const data = this.discountCardList.find(
        (item) => item.discountCardId === discountCardId
      );
      this.currentMember.discount = data ? data.discount : this.discountCardValue;
    },
    // 选择会员
    handleSelect(value, options) {
      const item = options.data.attrs['data-item'];
      this.currentMember = { ...item };
    },
  },
};
</script>

<style lang="less" scoped>
@import "../style/modules/variables";

// 优惠券样式开始

.yuan {
  position: absolute;
  top: 48px;
  left: -8px;
  width: 14px;
  height: 14px;
  border-radius: 100px;
  background-color: #fff;
}
.Title_name {
  width: 130px;
  margin-top: 1.5%;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 350;
  color: #333;
  line-height: 1;
}
// 优惠券样式结束

.goods__list {
  display: flex;
  flex-flow: row wrap;
  max-height: 358px;
  overflow: auto;
  border: 1px solid #ddd;
  border-radius: 5px;
}
.goods__item {
  display: flex;
  flex: 0 1 auto;
  position: relative;
  margin: 5px;
  padding: 3px 3px 0;
  text-align: center;
  //border: 1px solid @border-color-base;
  cursor: pointer;
  user-select: none;

  .goods__item__tips {
    display: none;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding-top: 20px;
    line-height: 20px;
    color: #fff;
    background: rgba(24, 144, 255, 0.5);
  }

  &:hover {
    .goods__item__tips {
      display: block;
    }
  }
}
//选座信息
.preCarList {
  display: flex;
  flex-flow: row wrap;
  max-height: 200px;
  overflow: auto;
  border: 1px solid #ddd;
  border-radius: 5px;
}
.preCarItem {
  display: flex;
  flex: 0 1 auto;
  position: relative;
  margin: 5px;
  padding: 3px 3px 0;
  text-align: center;
  //background-color: #5bc0de;
  cursor: pointer;
  user-select: none;
}
.preCarCardItem {
  //flex-shrink: 0;
  min-width: 100px;
  //margin-right: 20px;
  //background-color: #8c8c8c;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  border: 1px solid #8c8c8c;
  border-radius: 10px;
  margin-bottom: 20px;
  .preCarName {
    width: 130px;
    margin-top: 1.5%;
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 350;
    color: #333;
    line-height: 1;
  }
  .preCarChecked {
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: 24px;
    color: #ae6fda;
  }
}
.selectedCarCardItem {
  //flex-shrink: 0;
  min-width: 100px;
  //margin-right: 20px;
  background-color: #8c8c8c;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  border: 1px solid #8c8c8c;
  border-radius: 10px;
  margin-bottom: 20px;
  .preCarName {
    width: 130px;
    margin-top: 1.5%;
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 350;
    color: #333;
    line-height: 1;
  }
  .preCarChecked {
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: 24px;
    color: #ae6fda;
  }
}
//选座信息
.goods__table {
  margin-top: 10px;
  /*border: 1px solid red;*/
  /*max-height: 260px;*/
}

.goods__item--title,
.goods__item--text {
  width: 74px;
  word-break: break-all;
  font-size: 12px;
  margin: 5px 0;
  user-select: none;

  white-space: normal;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.goods__item--title {
  font-size: 18px;
}
.car_item {
  width: 246px;
  height: 160px;
  margin-right: 0px;
  margin-top: 1%;
  display: flex;
  flex-direction: row;
}

.bill {
  //border: 1px solid red;
  //font-size: 18px;
  //font-weight: 800;
  background-color: #409dff;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  color: #fff;
  display: flex;
  align-items: center;
}

.bill_tit {
  width: 38px;
  height: 126px;
  font-size: 18px;
  font-weight: 900;
  /* background-color: #0000FF; */
  display: flex;
  margin: 0 auto;
  align-items: center;
  vertical-align: center;
  justify-content: center;
  //border: 1px solid red;
  -webkit-writing-mode: vertical-lr;
  writing-mode: vertical-lr;
  text-overflow: ellipsis;
  white-space: overflow;
  overflow: hidden;
  line-height: 1.1;
}

.bill_content {
  width: 300px;
  padding-left: 8px;
  /* padding-right: 4%; */
  border: 1px solid #409dff;
}

.aaa {
  width: 92px;
  height: 120px;
  overflow: hidden;
  position: absolute;
  top: 8px;
  right: 5px;
}

.bbb {
  background-color: #409dff;
  text-align: center;
  font-size: 13px;
  font-weight: 450;

  color: #fff;
  word-spacing: -5px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  position: relative;
  padding: 0;
  left: 8px;
  top: 15px;
  width: 120px;
}
.ccc {
  color: #fff;
  background-color: #409dff;
  font-size: 13px;
  height: 22px;
  line-height: 20px;
  margin-right: 10px;
  display: inline-block;
  padding: 0 6px;
  border-radius: 100px;
}
.name {
  font-size: 19px;
  font-weight: 600;
  color: #333;
  margin-top: 2%;
  height: 50px;
  line-height: 24px;
  text-align: left;
  /* 2.超出部分隐藏 */
  overflow: hidden;
  /* 3.文字用省略号替代超出的部分 */
  text-overflow: ellipsis;
}

.remarks {
  //width: 163px;
  height: 38px;
  margin-top: 3px;
  display: block;
  //display: inline-block;
  //background-color: #f9f9f9;
  color: #8c8c8c;
  text-align: left;
}

.price_num {
  display: flex;
  float: row;
  justify-content: space-between;
}

.price {
  padding: 3px 0 0 0;
  font-size: 14px;
  color: #0383ff;
  display: flex;
  flex-direction: row;
}

.price_mem {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}
.good_price_mem {
  display: flex;
  flex-direction: row;
  margin-top: 25px;
}

.price_price {
  line-height: 40px;
  font-size: 26px;
  letter-spacing: -2px;
  font-weight: bold;
}
.price_buy {
  height: 22px;
  position: absolute;
  top: 125px;
  right: 12px;
  border: 1px solid #d0d0d0;
  color: #666;
  font-weight: 250;
  border-radius: 30px;
  padding: 0 7px 0 7px;
  font-size: 13px;
}

.validity {
  color: #9c9c9c;
  // margin: 9px 0 0 0;
  padding: 0;
  text-align: left;
  font-size: 8px;
  line-height: 1;
  letter-spacing: -0.4px;
  width: auto;
}

.price_big {
  font-size: 26px;
  font-weight: 800;
  letter-spacing: -1px;
}

.price_zero {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -1px;
}

.addto {
  width: 100px;
  margin-top: 10px;
  margin-left: 10px;
  height: 30px;
  line-height: 28px;
  text-align: center;
  color: #333333;
  font-size: 15px;
  font-weight: 500;
  border: 1px solid #d0d0d0;
  border-radius: 100px;
}

.choose {
  width: 100%;
  height: 800px;
  display: flex;
  flex-direction: row;
}
.couponCard {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  overflow-x: auto;
  margin-bottom: 20px;
}
.couponCardItem {
  flex-shrink: 0;
  min-width: 300px;
  margin-right: 20px;
  height: 110px;
  display: flex;
  flex-direction: row;
  position: relative;
  border: 1px solid red;
  border-radius: 10px;
  margin-bottom: 20px;
  .bbb {
    background: red;
  }
  .left {
    border-radius: 7px 0 0 7px;
    width: 100px;
    background: red;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    .l-inner {
      text-align: center;
      .l1 {
        span {
          font-size: 39px;
        }
      }
    }
  }
  .right {
    padding: 10px;
    position: relative;
    flex: 1;
    .name {
      font-size: 16px;
    }
    .indate {
      color: #adadad;
      font-size: 13px;
      margin-top: 6px;
    }
    .isShare {
      position: absolute;
      line-height: 35px;
      bottom: 0;
    }
  }
  .float-text {
    position: absolute;
    right: -6px;
    top: -8px;
  }
  .checked-ouline {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 34px;
    color: #ae6fda;
  }
}
.type-1 {
  border: 2px solid #ae6fda;
  .left {
    background: #ae6fda;
  }
  .bbb {
    background: #ae6fda;
  }
  .ccc {
    background: #ae6fda;
  }
  .checked-ouline {
    color: #ae6fda;
  }
}
.type-2 {
  border: 2px solid #ff6633;
  .left {
    background: #ff6633;
  }
  .bbb {
    background: #ff6633;
  }
  .ccc {
    background: #ff6633;
  }
  .checked-ouline {
    color: #ff6633;
  }
}
.type-3 {
  border: 2px solid #24c5c0;
  .left {
    background: #24c5c0;
  }
  .bbb {
    background: #24c5c0;
  }
  .ccc {
    background: #24c5c0;
  }
  .checked-ouline {
    color: #24c5c0;
  }
}
.type-4 {
  border: 2px solid #409dff;
  .left {
    background: #409dff;
  }
  .bbb {
    background: #409dff;
  }
  .checked-ouline {
    color: #409dff;
  }
}
.is-used {
  background: #cfcfcf;
  border: 2px solid #cfcfcf;
  .left {
    background: #959595;
  }
  .bbb {
    background: #959595;
  }
}
</style>
