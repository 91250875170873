<template>
  <a-modal
    v-model="visible"
    :maskClosable="false"
    title="开单"
    cancel-text="取消"
    width="1500px"
    :dialog-style="{ top: '10px' }"
    @cancel="handleCancel"
  >
    <a-form-model
      ref="form"
      :model="form"
      :label-col="{ span: 2 }"
      :wrapper-col="{ span: 22 }"
    >
      <a-row>
        <a-col>
          <a-form-model-item label="是否会员" prop="isMember">
            <a-radio-group
              button-style="solid"
              v-model="form.isMember"
              @change="handleIsMember"
            >
              <a-radio-button :value="2"> 否 </a-radio-button>
              <a-radio-button :value="1"> 是 </a-radio-button>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row v-if="form.isMember === 1">
        <a-col>
          <a-form-model-item label="会员卡号" prop="phone">
            <a-input-search
              placeholder="请输入会员卡号/手机号"
              v-model.trim="form.phone"
              @change="handleSearch"
              @pressEnter="handleSearch"
            >
            </a-input-search>
            <div>
              <span class="mr-10">会员卡号：{{ currentMember.userCode }}</span>
              <span class="mr-10"
              >姓名：{{ currentMember.realName || "-" }}</span
              >
              <span class="mr-10"
              >手机号：{{ currentMember.mobile || "-" }}</span
              >
              <span class="mr-10">余额：{{ currentMember.amount || 0 }}元</span>
              <span class="mr-10"
              >折扣比：{{ currentMember.discount || 1 }}</span
              >
              <span v-if="isYear"> 年卡结束时间：{{ currentMember.expiryTime }}</span>
              <span v-if="isSHow" :style="'color:' + endTimeColor"
              > 结束时间：{{ currentMember.endTime }}</span
              >
              <span v-if="isRecharge" :style="'color:' + rechargeTimeColor"
              >&nbsp;上次充值时间：{{ currentMember.updateTime }}</span
              >
            </div>
            <div style="color: red">
              <span>{{ currentMember.feeTip || '' }}</span>
              <span>{{ currentMember.feeTime || '' }}</span>
            </div>
            <a-row>
              <a-col>
                <img v-if="currentMember.timePicture" :src="currentMember.timePicture">
              </a-col>
            </a-row>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row v-if="form.isMember === 1 && currentMember.memberId && discountCardList.length >0">
        <a-col>
          <a-form-model-item label="会员折扣比" prop="discountCard">
            <a-select
              v-model="selectedDiscount"
              allowClear
              @change="handleChangeDiscountCard"
            >
              <a-select-option
                v-for="item in discountCardList"
                :key="item.discountCardId"
              >{{ item.discount }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col>
          <a-form-model-item label="请选择" prop="goodsData">
            <a-tabs v-model="tabIndex">
              <a-tab-pane key="1" tab="分类车辆">
                <j-category-car-select
                  placeholder="请选择分类"
                  v-model="carCategoryId"
                  @change="handelChangeCarCategoryId"
                />
                <ul class="goods__list">
                  <li
                    class="goods__item"
                    v-for="item in groupVehicleList"
                    :key="item.kartClassifyId"
                    @contextmenu.prevent.stop="handleAddGoods(item, true)"
                    @click="handleAddGoods(item)"
                  >
                    <div class="car_item">
                      <div
                        class="bill"
                        :style="'background-color:' + item.color"
                      >
                        <span class="bill_tit">{{ item.carCategoryName }}</span>
                      </div>
                      <div
                        class="bill_content"
                        :style="'border:1px solid ' + item.color"
                      >
                        <div class="name">{{ item.mName }}</div>
                        <div class="remarks">
                          <span
                            style="
                              background-color: #f5f5f5;
                              font-size: 13px;
                              padding: 2px 5px;
                              border-radius: 15px;
                              letter-sapcing: 1px;
                            "
                          >{{ item.name }}</span
                          >
                        </div>
                        <div
                          class="validity"
                          :style="'color:' + item.fontColor"
                        >
                          有效期：{{ item.periodTypeName }}
                        </div>
                        <div class="price_num">
                          <div class="price" :style="'color:' + item.color">
                            <div class="price_mem">
                              <div style="line-height: 45px; color: #666">
                                会员价
                              </div>
                              <div class="price_price">
                                {{ item.memberPrice }}
                              </div>
                            </div>
                            <div class="price_buy">点击购买</div>
                          </div>
                        </div>

                        <div>
                          <div class="aaa">
                            <div
                              class="bbb"
                              :style="'background-color:' + item.bgColor"
                            >
                              <span :style="'color:' + item.fontColor"
                              >原￥{{ item.price }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="goods__item__tips">
                      <a-icon style="font-size: 40px" type="shopping-cart" />
                      <p>点击选座</p>
                    </div>
                  </li>
                </ul>
              </a-tab-pane>
            </a-tabs>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <template slot="footer">
      <a-button key="back" @click="handleCancel"> 取消 </a-button>
    </template>
  </a-modal>
</template>

<script>
import moment from "moment";
import ACol from "ant-design-vue/es/grid/Col";
import { getByCodeOrMobile, queryEffectiveByMobile } from "@/api/member";
import { vehicleQueryAll, vehicleQueryAllByCategoryId } from "@/api/vehicle";
import { normalList, memberList } from '@/api/level';
import JCategoryCarSelect from "@/components/JCategoryCarSelect";
import { filterMultiDictText } from "@/components/dict/JDictSelectUtil";
import { loadCategoryCarData } from "@/api/manage";
import { formatDate } from "@/libs/utils";

export default {
  name: 'CreateDiySelectGroupModal',
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ACol,
    JCategoryCarSelect,
  },
  data() {
    return {
      discountCardList: [],
      discountCardValue: 1,
      selectedDiscount: 1,
      isYear: false,
      isSHow: false,
      isRecharge: false,
      rechargeTimeColor: 'black',
      endTimeColor: 'black',
      carDictOptions: {},
      dictOptions: {},
      carCategoryId: '',
      categoryId: '',
      visible: this.value,
      discountList: [],
      memberList: [],
      vehicleRuleList: [],
      groupVehicleList: [],
      selectedVehicleList: [],
      selectedGroupVehicleList: [],
      baseNormalList: [],
      mergerList: [],
      currentMember: {},
      form: {
        goodsData: [],
        memberInfo: {},
        isMember: 2, // 是否会员
      },
      tabIndex: '1',
    };
  },
  computed: {
    todayDate() {
      moment(new Date()).format('YYYY-MM-DD 23:59:59');
    },
  },
  watch: {
    'form.goodsData': {
      handler(newVal) {
        console.log('-----form.goodsData-------------', newVal);
      },
      deep: true,
    },
    value(val) {
      this.visible = val;
      if (val) {
        // 进入界面才去初始化，取消界面就啥也不操作了
        console.log('进入自助机车辆选择界面');
        this.intiAll();
      }
    },
  },
  created() {
    // 这个地方啥也不干，调试时看看效果
    // console.log('自助机组别选择界面 created');

    // this.getData();
    // setTimeout(() => {
    //   this.initCarDictConfig();
    // }, 600);
  },
  methods: {
    // 初始化
    intiAll() {
      this.tabIndex = '1';
      this.form.isMember = 2;
      this.isYear = false;
      this.isSHow = false;
      this.isRecharge = false;
      this.currentMember = {};
      this.form.phone = '';
      this.selectedVehicleList = [];
      this.selectedGroupVehicleList = [];
      this.getData();
    },
    // 计算到期时间
    calDiffDay(date2) {
      const todayDate = moment(new Date());
      const endDate = moment(date2).format('YYYY-MM-DD');
      return todayDate.diff(endDate, 'days');
    },
    getData() {
      const switchData = this.$store.getters.getStoreInfo.feeSwitch;
      if (switchData === 1) {
        this.form.isMember = 1;
      }
      this.initNormalList();
      this.getVehicleRuleList();
      this.initCarDictConfig();
    },
    handleIsMember() {
      console.log('== handleIsMember1 ========');
      if (this.form.isMember === 2) {
        // 重置优惠劵信息
        // this.resetCoupon();
        console.log('散客');
        // 重置散客车辆信息
        this.resetFIT();
      } else if (this.form.isMember === 1) {
        console.log('会员');
        this.groupVehicleList = [];
        if (this.currentMember.memberId) {
          console.log('会员ID不为空');
          // this.getMemebrCouponListRequest();
          this.groupVehicleList = this.selectedVehicleList;
        }
        if (this.form.phone) {
          this.handleSearch1(this.form.phone);
        }
      }
    },
    // 查找会员 13466639190
    handleSearch(e) {
      const searchText = e.target.value;
      getByCodeOrMobile(searchText).then((data) => {
        this.currentMember = data || {};
        if (this.currentMember.memberId) {
          console.log('会员信息');
          console.log(this.currentMember);
          this.queryDiscountCardList();
          console.log('这个地方查回来会员等级对应的车辆，合并');
          memberList(this.currentMember.memberId).then((res) => {
            // 查到的会员车信息
            console.log('查到的会员车信息');
            console.log(res);
            if (res && res.length > 0) {
              console.log('合并会员车信息');
              this.mergerList = this.baseNormalList.concat(res);
              console.log(this.mergerList);
            } else {
              console.log('会员车信息为空');
              this.mergerList = this.baseNormalList;
            }
            this.resetMEMBER();
          });
          if (this.currentMember.expiryTime != null) {
            this.isYear = true;
          }
          const todayDate = moment(new Date());
          // 如果是会费会员，则判断是否在有效期类
          if (this.$store.getters.getStoreInfo.feeSwitch === 1) {
            if (this.currentMember.feeEndTime != null) {
              const feeStartTime = moment(this.currentMember.feeStartTime).format('YYYY-MM-DD');
              const feeEndTime = moment(this.currentMember.feeEndTime).format('YYYY-MM-DD');
              const diffDays = todayDate.diff(feeEndTime, 'days');
              console.log(diffDays);
              if (diffDays > 0) {
                this.currentMember.feeTip = '会员已到期';
              } else {
                this.currentMember.feeTime = `开始时间：${feeStartTime}-结束时间：${feeEndTime}，剩余：${Math.abs(diffDays)}天，会费折扣率：${this.currentMember.feeDiscount}`;
              }
            } else {
              this.currentMember.feeTip = '无效会员或会员已到期';
            }
          } else {
            if (this.currentMember.updateTime != null) {
              this.isRecharge = true;
              const updateTime = moment(this.currentMember.updateTime);
              const diffDays = todayDate.diff(updateTime, 'days');
              console.log(diffDays);
              if (diffDays > 30) {
                this.rechargeTimeColor = 'red';
              }
            }
            if (this.currentMember.endTime != null) {
              this.isSHow = true;
              const v1 = moment(this.currentMember.endTime).format(
                'YYYY-MM-DD 23:59:59'
              );
              const v2 = moment(new Date()).format('YYYY-MM-DD 23:59:59');
              console.log(v1);
              console.log(v2);
              const isBefore = moment(v1).isBefore(moment(v2));
              if (isBefore) {
                this.endTimeColor = 'red';
              }
            }
          }
        } else {
          this.isYear = false;
          this.isSHow = false;
          this.endTimeColor = 'black';
          this.isRecharge = false;
          this.rechargeTimeColor = 'black';
          this.groupVehicleList = [];
        }
      });
    },
    // 查找会员 13466639190
    handleSearch1(e) {
      getByCodeOrMobile(e).then((data) => {
        this.currentMember = data || {};
        if (this.currentMember.memberId) {
          console.log('会员信息');
          console.log(this.currentMember);
          this.queryDiscountCardList();
          console.log('这个地方查回来会员等级对应的车辆，合并');
          memberList(this.currentMember.memberId).then((res) => {
            // 查到的会员车信息
            console.log('查到的会员车信息');
            console.log(res);
            if (res && res.length > 0) {
              console.log('合并会员车信息');
              this.mergerList = this.baseNormalList.concat(res);
              console.log(this.mergerList);
            } else {
              console.log('会员车信息为空');
              this.mergerList = this.baseNormalList;
            }
            this.resetMEMBER();
          });
          if (this.currentMember.expiryTime != null) {
            this.isYear = true;
          }
          const todayDate = moment(new Date());
          // 如果是会费会员，则判断是否在有效期类
          if (this.$store.getters.getStoreInfo.feeSwitch === 1) {
            if (this.currentMember.feeEndTime != null) {
              const feeStartTime = moment(this.currentMember.feeStartTime).format('YYYY-MM-DD');
              const feeEndTime = moment(this.currentMember.feeEndTime).format('YYYY-MM-DD');
              const diffDays = todayDate.diff(feeEndTime, 'days');
              console.log(diffDays);
              if (diffDays > 0) {
                this.currentMember.feeTip = '会员已到期';
              } else {
                this.currentMember.feeTime = `开始时间：${feeStartTime}-结束时间：${feeEndTime}，剩余：${Math.abs(diffDays)}天，会费折扣率：${this.currentMember.feeDiscount}`;
              }
            } else {
              this.currentMember.feeTip = '无效会员或会员已到期';
            }
          } else {
            if (this.currentMember.updateTime != null) {
              this.isRecharge = true;
              const updateTime = moment(this.currentMember.updateTime);
              const diffDays = todayDate.diff(updateTime, 'days');
              console.log(diffDays);
              if (diffDays > 30) {
                this.rechargeTimeColor = 'red';
              }
            }
            if (this.currentMember.endTime != null) {
              this.isSHow = true;
              const v1 = moment(this.currentMember.endTime).format(
                'YYYY-MM-DD 23:59:59'
              );
              const v2 = moment(new Date()).format('YYYY-MM-DD 23:59:59');
              console.log(v1);
              console.log(v2);
              const isBefore = moment(v1).isBefore(moment(v2));
              if (isBefore) {
                this.endTimeColor = 'red';
              }
            }
          }
        } else {
          this.isYear = false;
          this.isSHow = false;
          this.endTimeColor = 'black';
          this.isRecharge = false;
          this.rechargeTimeColor = 'black';
          this.groupVehicleList = [];
        }
      });
    },
    // 查找会员折扣卡
    queryDiscountCardList() {
      this.discountCardValue = this.currentMember.discount;
      this.selectedDiscount = this.discountCardValue;
      queryEffectiveByMobile(this.currentMember.mobile).then((data) => {
        this.discountCardList = data;
        if (this.discountCardList.length > 0) {
          if (this.discountCardList[0].discount < this.discountCardValue) {
            this.selectedDiscount = this.discountCardList[0].discount;
            this.currentMember.discount = this.selectedDiscount;
          }
        }
        console.log(this.discountCardList);
      });
    },
    // 选择折扣卡
    handleChangeDiscountCard(discountCardId) {
      const data = this.discountCardList.find(
        (item) => item.discountCardId === discountCardId
      );
      this.currentMember.discount = data ? data.discount : this.discountCardValue;
    },
    // 选择会员
    handleSelect(value, options) {
      const item = options.data.attrs['data-item'];
      this.currentMember = { ...item };
    },

    // 获取计费规则列表
    getVehicleRuleList() {
      const that = this;
      vehicleQueryAll().then((data) => {
        if (data) {
          data.forEach((item) => {
            item.cName = item.kartClassifyName; // 订单列表中显示
            item.mName = item.kartClassifyName.slice(0, 8); // 展示图片上的名称
            // item.mName = item.kartClassifyName.slice(0, 4); // 展示图片上的名称
            item.mId = item.kartClassifyId;
            item.name = item.kartClassifySpec.slice(0, 24);
            // item.name = item.kartClassifyName;
            // item.price = item.price; // 已有
            item.carCategoryName = filterMultiDictText(
              that.carDictOptions.carCategoryId,
              item.carCategoryId
            );
            item.carCategoryName = item.carCategoryName.slice(0, 12);
            if (item.periodType === 0) {
              item.periodTypeName = '长期有效';
            } else {
              item.periodTypeName = `${formatDate(
                item.startDate
              )} - ${formatDate(item.endDate)}`;
            }
            item.source = 1;
            item.num = 0;
            item.total = 0;
            // 蓝色 #409dff 浅蓝色 #eaf4ff
            // 橙色 #ff6633 浅橙色 #ffebe5
            // 黄色 #fec22b 浅黄色 #fff4d8
            // 青色 #24c5c0 浅青色 #e8fbfa
            // 绿色 #45be8c 浅绿色 #e1f4ec
            // 紫色 #ae6fda 浅紫色 #f5eafd
            item.bgColor = '#eaf4ff';
            item.fontColor = '#016cd4';
            if (item.color === '#409dff') {
              item.bgColor = '#eaf4ff';
              item.fontColor = '#016cd4';
            } else if (item.color === '#ff6633') {
              item.bgColor = '#ffebe5';
              item.fontColor = '#ff6633';
            } else if (item.color === '#fec22b') {
              item.bgColor = '#fff4d8';
              item.fontColor = '#eca900';
            } else if (item.color === '#24c5c0') {
              item.bgColor = '#e8fbfa';
              item.fontColor = '#24c5c0';
            } else if (item.color === '#45be8c') {
              item.bgColor = '#e1f4ec';
              item.fontColor = '#45be8c';
            } else if (item.color === '#ae6fda') {
              item.bgColor = '#f5eafd';
              item.fontColor = '#ae6fda';
            }
            // 算出新的列表
            if (this.baseNormalList.includes(item.carCategoryId)) {
              // 满足条件
              console.log('满足条件', item.carCategoryId);
              this.selectedVehicleList.push(item);
            }
          });
          console.log(this.selectedVehicleList);
          this.vehicleRuleList = data;
          // this.groupVehicleList = data;
          this.groupVehicleList = this.selectedVehicleList;
        }
      });
    },
    handelChangeCarCategoryId() {
      console.log(this.carCategoryId);
      const that = this;
      if (this.carCategoryId) {
        vehicleQueryAllByCategoryId(this.carCategoryId).then((data) => {
          console.log(data);
          // console.log('组别中的值1');
          this.selectedGroupVehicleList = [];
          data.forEach((item) => {
            item.cName = item.kartClassifyName; // 订单列表中显示
            item.mName = item.kartClassifyName.slice(0, 8); // 展示图片上的名称
            // item.mName = item.kartClassifyName.slice(0, 4); // 展示图片上的名称
            item.mId = item.kartClassifyId;
            item.name = item.kartClassifySpec.slice(0, 24);
            // item.name = item.kartClassifyName;
            // item.price = item.price; // 已有
            item.carCategoryName = filterMultiDictText(
              that.carDictOptions.carCategoryId,
              item.carCategoryId
            );
            item.carCategoryName = item.carCategoryName.slice(0, 12);
            if (item.periodType === 0) {
              item.periodTypeName = '长期有效';
            } else {
              item.periodTypeName = `${formatDate(
                item.startDate
              )} - ${formatDate(item.endDate)}`;
            }
            item.source = 1;
            item.num = 0;
            item.total = 0;
            // 蓝色 #409dff 浅蓝色 #eaf4ff
            // 橙色 #ff6633 浅橙色 #ffebe5
            // 黄色 #fec22b 浅黄色 #fff4d8
            // 青色 #24c5c0 浅青色 #e8fbfa
            // 绿色 #45be8c 浅绿色 #e1f4ec
            // 紫色 #ae6fda 浅紫色 #f5eafd
            item.bgColor = '#eaf4ff';
            item.fontColor = '#016cd4';
            if (item.color === '#409dff') {
              item.bgColor = '#eaf4ff';
              item.fontColor = '#016cd4';
            } else if (item.color === '#ff6633') {
              item.bgColor = '#ffebe5';
              item.fontColor = '#ff6633';
            } else if (item.color === '#fec22b') {
              item.bgColor = '#fff4d8';
              item.fontColor = '#eca900';
            } else if (item.color === '#24c5c0') {
              item.bgColor = '#e8fbfa';
              item.fontColor = '#24c5c0';
            } else if (item.color === '#45be8c') {
              item.bgColor = '#e1f4ec';
              item.fontColor = '#45be8c';
            } else if (item.color === '#ae6fda') {
              item.bgColor = '#f5eafd';
              item.fontColor = '#ae6fda';
            }
            if (this.form.isMember === 1) {
              // 算出新的列表
              if (this.mergerList.includes(item.carCategoryId)) {
                // 组别中的值
                // console.log('组别中的值');
                this.selectedGroupVehicleList.push(item);
              }
            } else {
              // 算出新的列表
              if (this.baseNormalList.includes(item.carCategoryId)) {
                // 组别中的值
                // console.log('组别中的值');
                this.selectedGroupVehicleList.push(item);
              }
            }

          });
          if (this.form.isMember === 1) {
            if (this.currentMember.memberId) {
              this.groupVehicleList = this.selectedGroupVehicleList;
            } else {
              this.groupVehicleList = [];
            }
          } else {
            this.groupVehicleList = this.selectedGroupVehicleList;
          }
        });
      } else {
        if (this.form.isMember === 1) {
          if (this.currentMember.memberId) {
            this.groupVehicleList = this.selectedVehicleList;
          } else {
            this.groupVehicleList = [];
          }
        } else {
          this.groupVehicleList = this.selectedVehicleList;
        }
      }
    },
    // 选择组别
    handleAddGoods(goods, contextmenu = false) {
      if (contextmenu && !goods.num) {
        return;
      }
      const item = goods;
      if (item.carCategoryId === '0') {
        this.$message.error('请配置组别信息');
        return;
      }
      // 将会员信息，商品信息传递到下个页面
      this.visible = false;
      this.$emit('input', false);
      // 重新组装信息，将会员信息，商品信息传递到下个页面
      const sendData = {
        goodsInfo: item,
        currentMember: this.currentMember,
        isMember: this.form.isMember,
        isYear: this.isYear,
        isSHow: this.isSHow,
        isRecharge: this.isRecharge,
        carCategoryId: item.carCategoryId
      };
      this.$emit('on-ok', sendData);
    },

    // 取消按钮
    handleCancel() {
      // 复位基本信息
      this.carCategoryId = '';
      this.groupVehicleList = [];
      this.$refs.form.resetFields();
      this.currentMember = {};
      this.form.fee = 0;
      this.visible = false;
      this.form.phone = '';
      this.$emit('input', false);
      this.$emit('on-cancel');
    },
    // 初始化车辆字典
    initCarDictConfig() {
      loadCategoryCarData({ code: '0' }).then((res) => {
        console.log('自助机初始化车辆字典值', res);
        if (res !== undefined) {
          this.$set(this.carDictOptions, 'carCategoryId', res);
        }
      }).catch((err) => {
        console.log('自助机初始化车辆字典值', err);
      });
    },
    computedIndate(item) {
      const temp = `${item.startTime}至${item.endTime}`;
      return temp;
    },
    initNormalList() {
      normalList().then((res) => {
        console.log('自助机初始化普通车类型值', res);
        this.baseNormalList = res;
      });
    },
    resetFIT() {
      // 重置散客车辆信息
      this.selectedVehicleList = [];
      this.vehicleRuleList.forEach((item) => {
        // 算出新的列表
        if (this.baseNormalList.includes(item.carCategoryId)) {
          // 满足条件
          console.log('满足条件', item.carCategoryId);
          this.selectedVehicleList.push(item);
        }
      });
      this.groupVehicleList = this.selectedVehicleList;
    },
    resetMEMBER() {
      // 重置会员车辆信息
      this.selectedVehicleList = [];
      this.vehicleRuleList.forEach((item) => {
        // 算出新的列表
        if (this.mergerList.includes(item.carCategoryId)) {
          // 满足条件
          console.log('满足条件', item.carCategoryId);
          this.selectedVehicleList.push(item);
        }
      });
      this.groupVehicleList = this.selectedVehicleList;
    }
  },
};
</script>

<style lang="less" scoped>
@import "../style/modules/variables";

// 优惠券样式开始

.yuan {
  position: absolute;
  top: 48px;
  left: -8px;
  width: 14px;
  height: 14px;
  border-radius: 100px;
  background-color: #fff;
}
.Title_name {
  width: 130px;
  margin-top: 1.5%;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 350;
  color: #333;
  line-height: 1;
}
// 优惠券样式结束

.goods__list {
  display: flex;
  flex-flow: row wrap;
  max-height: 358px;
  overflow: auto;
  border: 1px solid #ddd;
  border-radius: 5px;
}
.goods__item {
  display: flex;
  flex: 0 1 auto;
  position: relative;
  margin: 5px;
  padding: 3px 3px 0;
  text-align: center;
  //border: 1px solid @border-color-base;
  cursor: pointer;
  user-select: none;

  .goods__item__tips {
    display: none;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding-top: 20px;
    line-height: 20px;
    color: #fff;
    background: rgba(24, 144, 255, 0.5);
  }

  &:hover {
    .goods__item__tips {
      display: block;
    }
  }
}

.goods__table {
  margin-top: 10px;
  /*border: 1px solid red;*/
  /*max-height: 260px;*/
}

.goods__item--title,
.goods__item--text {
  width: 74px;
  word-break: break-all;
  font-size: 12px;
  margin: 5px 0;
  user-select: none;

  white-space: normal;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.goods__item--title {
  font-size: 18px;
}
.car_item {
  width: 246px;
  height: 160px;
  margin-right: 0px;
  margin-top: 1%;
  display: flex;
  flex-direction: row;
}

.bill {
  //border: 1px solid red;
  //font-size: 18px;
  //font-weight: 800;
  background-color: #409dff;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  color: #fff;
  display: flex;
  align-items: center;
}

.bill_tit {
  width: 38px;
  height: 126px;
  font-size: 18px;
  font-weight: 900;
  /* background-color: #0000FF; */
  display: flex;
  margin: 0 auto;
  align-items: center;
  vertical-align: center;
  justify-content: center;
  //border: 1px solid red;
  -webkit-writing-mode: vertical-lr;
  writing-mode: vertical-lr;
  text-overflow: ellipsis;
  white-space: overflow;
  overflow: hidden;
  line-height: 1.1;
}

.bill_content {
  width: 300px;
  padding-left: 8px;
  /* padding-right: 4%; */
  border: 1px solid #409dff;
}

.aaa {
  width: 92px;
  height: 120px;
  overflow: hidden;
  position: absolute;
  top: 8px;
  right: 5px;
}

.bbb {
  background-color: #409dff;
  text-align: center;
  font-size: 13px;
  font-weight: 450;

  color: #fff;
  word-spacing: -5px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  position: relative;
  padding: 0;
  left: 8px;
  top: 15px;
  width: 120px;
}
.ccc {
  color: #fff;
  background-color: #409dff;
  font-size: 13px;
  height: 22px;
  line-height: 20px;
  margin-right: 10px;
  display: inline-block;
  padding: 0 6px;
  border-radius: 100px;
}
.name {
  font-size: 19px;
  font-weight: 600;
  color: #333;
  margin-top: 2%;
  line-height: 36px;
  text-align: left;
}

.remarks {
  width: 163px;
  height: 38px;
  margin-top: 3px;
  display: block;
  //display: inline-block;
  //background-color: #f9f9f9;
  color: #8c8c8c;
  text-align: left;
}

.price_num {
  display: flex;
  float: row;
  justify-content: space-between;
}

.price {
  padding: 3px 0 0 0;
  font-size: 14px;
  color: #0383ff;
  display: flex;
  flex-direction: row;
}

.price_mem {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}
.good_price_mem {
  display: flex;
  flex-direction: row;
  margin-top: 25px;
}

.price_price {
  line-height: 40px;
  font-size: 26px;
  letter-spacing: -2px;
  font-weight: bold;
}
.price_buy {
  height: 22px;
  position: absolute;
  top: 125px;
  right: 12px;
  border: 1px solid #d0d0d0;
  color: #666;
  font-weight: 250;
  border-radius: 30px;
  padding: 0 7px 0 7px;
  font-size: 13px;
}

.validity {
  color: #9c9c9c;
  // margin: 9px 0 0 0;
  padding: 0;
  text-align: left;
  font-size: 8px;
  line-height: 1;
  letter-spacing: -0.4px;
  width: auto;
}

.price_big {
  font-size: 26px;
  font-weight: 800;
  letter-spacing: -1px;
}

.price_zero {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -1px;
}

.addto {
  width: 100px;
  margin-top: 10px;
  margin-left: 10px;
  height: 30px;
  line-height: 28px;
  text-align: center;
  color: #333333;
  font-size: 15px;
  font-weight: 500;
  border: 1px solid #d0d0d0;
  border-radius: 100px;
}

.choose {
  width: 100%;
  height: 800px;
  display: flex;
  flex-direction: row;
}
.couponCard {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  overflow-x: auto;
  margin-bottom: 20px;
}
.couponCardItem {
  flex-shrink: 0;
  min-width: 300px;
  margin-right: 20px;
  height: 110px;
  display: flex;
  flex-direction: row;
  position: relative;
  border: 1px solid red;
  border-radius: 10px;
  margin-bottom: 20px;
  .bbb {
    background: red;
  }
  .left {
    border-radius: 7px 0 0 7px;
    width: 100px;
    background: red;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    .l-inner {
      text-align: center;
      .l1 {
        span {
          font-size: 39px;
        }
      }
    }
  }
  .right {
    padding: 10px;
    position: relative;
    flex: 1;
    .name {
      font-size: 16px;
    }
    .indate {
      color: #adadad;
      font-size: 13px;
      margin-top: 6px;
    }
    .isShare {
      position: absolute;
      line-height: 35px;
      bottom: 0;
    }
  }
  .float-text {
    position: absolute;
    right: -6px;
    top: -8px;
  }
  .checked-ouline {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 34px;
    color: #ae6fda;
  }
}
.type-1 {
  border: 2px solid #ae6fda;
  .left {
    background: #ae6fda;
  }
  .bbb {
    background: #ae6fda;
  }
  .ccc {
    background: #ae6fda;
  }
  .checked-ouline {
    color: #ae6fda;
  }
}
.type-2 {
  border: 2px solid #ff6633;
  .left {
    background: #ff6633;
  }
  .bbb {
    background: #ff6633;
  }
  .ccc {
    background: #ff6633;
  }
  .checked-ouline {
    color: #ff6633;
  }
}
.type-3 {
  border: 2px solid #24c5c0;
  .left {
    background: #24c5c0;
  }
  .bbb {
    background: #24c5c0;
  }
  .ccc {
    background: #24c5c0;
  }
  .checked-ouline {
    color: #24c5c0;
  }
}
.type-4 {
  border: 2px solid #409dff;
  .left {
    background: #409dff;
  }
  .bbb {
    background: #409dff;
  }
  .checked-ouline {
    color: #409dff;
  }
}
.is-used {
  background: #cfcfcf;
  border: 2px solid #cfcfcf;
  .left {
    background: #959595;
  }
  .bbb {
    background: #959595;
  }
}
</style>
